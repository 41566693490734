import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./privacyPolicy.css";

const PrivacyPolicy = () => {
  useEffect(() => {
    const container = document.getElementById("enzuzo-container");
    if (container) {
      const script = document.createElement("script");
      script.src = "https://app.enzuzo.com/scripts/privacy/47337b00-df30-11ee-812d-d336526ba4e9";
      script.async = true;
      script.id = "__enzuzo-root-script";
      container.appendChild(script);
    } else {
      console.error("enzuzo-container element not found");
    }
  }, []);

  return (
    <div>
      <div id="enzuzo-container"></div>
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        <Link to="/home" style={{ textDecoration: "none", color: "#3F94F8" }}>
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
