import React, { createContext, useContext, useState } from "react";

const NoticeContext = createContext();

export const useNotice = () => useContext(NoticeContext);

const NoticeProvider = ({ children }) => {
  const [notices, setNotices] = useState([]);

  const addNotice = (notice) => {
    const id = Math.random().toString(36).substr(2, 9);
    setNotices((prevNotices) => [...prevNotices, { ...notice, id }]);

    setTimeout(() => {
      removeNotice(id);
    }, 5000);
  };

  const removeNotice = (id) => {
    setNotices((prevNotices) => prevNotices.filter((notice) => notice.id !== id));
  };

  return <NoticeContext.Provider value={{ notices, addNotice, removeNotice }}>{children}</NoticeContext.Provider>;
};

export default NoticeProvider;
