import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useNotice } from "../context/NoticeContext";
import useAuthContext from "../hooks/useAuthContext";

axios.defaults.withCredentials = true;

const GitHubCallback = () => {
  const navigate = useNavigate();
  const { addNotice } = useNotice();
  const { dispatch } = useAuthContext();

  useEffect(() => {
    const handleGithubLogin = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");

      if (!code) return;

      try {
        const tokenResponse = await axios.post(process.env.REACT_APP_REDIRECT_URI, { code }, { withCredentials: true });

        const { accessToken, email } = tokenResponse.data;

        if (accessToken && email) {
          await axios.post(`${process.env.REACT_APP_API_URL}/checkuserexists`, {
            type: "github",
            email: email,
          });

          const response = await axios.post(`${process.env.REACT_APP_API_URL}/exchange-github-access-token`, {
            token: accessToken,
            email,
          });

          if (response.data.userToken) {
            const userObject = {
              token: response.data.userToken,
              email: email,
              signupStatus: "active",
            };

            localStorage.setItem("user", JSON.stringify(userObject));

            navigate("/home");
          }
        }
      } catch (err) {
        addNotice({
          type: "error",
          message: "Could not authenticate GitHub user. Please try again.",
        });
        navigate("/login");
        console.error("Login failed:", err);
      }
    };

    handleGithubLogin();
  }, [navigate, dispatch, addNotice]);
};

export default GitHubCallback;
