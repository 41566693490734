import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useModal } from "../../context/ModalContext";
import { disableScroll, enableScroll } from "../../utils/scrollLock";
import "./modal.min.css";

const Modal = () => {
  const { activeModal, closeModal } = useModal();

  useEffect(() => {
    if (activeModal) {
      disableScroll();
    }
    return () => {
      if (activeModal) {
        enableScroll();
      }
    };
  }, [activeModal]);

  useEffect(() => {
    const handleEscape = (event) => {
      if (activeModal && activeModal.props.canBeClosed && event.keyCode === 27) closeModal();
    };

    window.addEventListener("keydown", handleEscape);

    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, [closeModal, activeModal]);

  const handleClose = () => {
    if (activeModal && activeModal.props.canBeClosed) {
      closeModal();
    }
  };

  if (!activeModal) return null;

  const { id, props } = activeModal;

  return (
    <div className="modal-overlay" onClick={handleClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2>{props.title || "Modal"}</h2>
          {props.canBeClosed && (
            <div className="modal-close-btn" onClick={handleClose}>
              <FontAwesomeIcon icon={faXmark} />
            </div>
          )}
        </div>
        <div id={id} className="modal-body">
          {props.content}
        </div>
      </div>
    </div>
  );
};

export default Modal;
