import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useNotice } from "../../context/NoticeContext";

const Confirm = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { addNotice } = useNotice();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/confirmemail/${token}`);

        addNotice({
          type: "success",
          message: response.data.message,
        });

        navigate("/home");
      } catch (error) {
        addNotice({
          type: "error",
          message: "Error verifying email. Please try again or contact support.",
        });
        console.error("Error verifying email:", error);
        navigate("/");
      }
    };

    verifyEmail();
  }, []);

  return <></>;
};

export default Confirm;
