import axios from "axios";

export const fetchCourses = async (userToken) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/getallcourses`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};

export const fetchCategories = async (userToken, courseId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/coursequestions/${courseId}`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      withCredentials: true,
    });
    return response.data.categories;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};

export const submitCode = async (courseId, code, questionIndex, userToken) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/code/${courseId}`,
      {
        answer: code,
        questionIndex: questionIndex,
      },
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
