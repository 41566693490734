import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import correctSound from "../../assets/sounds/correct.wav";
import exceptionalSound from "../../assets/sounds/exceptional.wav";
import incorrectSound from "../../assets/sounds/incorrect.wav";
import { useConsecutiveContext } from "../../context/ConsequitiveContext";
import { useNotice } from "../../context/NoticeContext";
import { submitCode } from "../../utils/apiCalls";
import { generateUniqueId } from "../../utils/helpers";
import { disableScroll, enableScroll } from "../../utils/scrollLock";
import CodeEditor from "../CodeEditor/CodeEditor";
import QuestionAndAnswerEdit from "../QuestionAndAnswerEdit";
import "./questionModal.min.css";

const QuestionModal = ({
  courseId,
  isOpen,
  closeQuestionModal,
  content,
  defineStartingCode,
  currentQuestionIndex,
  goToQuestion,
  updateQuestionAsAnswered,
  categories,
  loading,
  setLoading,
  checkIfUserHasAccess,
  userData,
}) => {
  const [feedback, setFeedback] = useState("");
  const [displayfeedbackButton, setDisplayFeedbackButton] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [isExceptional, setisExceptional] = useState(false);
  const [categoryProgress, setCategoryProgress] = useState(0);
  const [consecutiveValue, setConsecutiveValue] = useState(0);
  const [code, setCode] = useState("");
  const [reportSubmitted, setReportSubmitted] = useState(false);
  const [focusTrigger, setFocusTrigger] = useState(0);
  const [token, setToken] = useState(null);
  const { addNotice } = useNotice();

  useEffect(() => {
    const userString = localStorage.getItem("user");
    if (userString) {
      try {
        const parsedUserData = JSON.parse(userString);
        const parsedSessionToken = parsedUserData.token;
        setToken(parsedSessionToken);
      } catch (error) {
        console.error("Error parsing user data:", error);
      }
    }
  }, [setToken]);

  const initialCode = useMemo(() => defineStartingCode(content), [content, defineStartingCode]);

  const submittedIdRef = useRef(null);

  const enableCodeSubmit = useMemo(() => {
    return code && code !== initialCode && !loading;
  }, [code, initialCode, loading]);

  const scrollToBottom = () => {
    const questionModal = document.getElementById("questionModal");
    questionModal.scrollTop = questionModal.scrollHeight;
  };

  const typeFeedback = useCallback((responseText, currentText = "", currentOperationId) => {
    if (responseText.length > 0 && submittedIdRef.current === currentOperationId) {
      const nextChar = responseText.charAt(0);
      const newText = currentText + nextChar;
      setFeedback(newText);
      setTimeout(() => {
        typeFeedback(responseText.slice(1), newText, currentOperationId);
        scrollToBottom();
      }, 0);
    } else {
      setDisplayFeedbackButton(true);
    }
  }, []);

  const clearFeedback = () => {
    setDisplayFeedbackButton(false);
    setFeedback("");
    setReportSubmitted(false);
    setIsCorrect("");
    setFocusTrigger((prev) => prev + 1);
  };

  const resetModal = () => {
    setCode(initialCode);
    setIsCorrect("");
    clearFeedback();
    setisExceptional(false);
    setFocusTrigger((prev) => prev + 1);
    submittedIdRef.current = null;
  };

  const handleQuestionNavigation = (direction) => {
    goToQuestion(direction);
    resetModal();
  };

  const handleExampleSolution = async () => {
    setLoading(true);

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/examplesolution`, {
        params: {
          currentQuestionIndex: currentQuestionIndex,
        },
      });

      const codePrepend = ["// Example Solution:", "", ""].join("\n");
      const analysisPrepend = "/*\n\n";
      const prepend = content.genre === "code" ? codePrepend : analysisPrepend;
      const append = content.genre === "analysis" ? "\n\n*/" : "";
      const exampleSolution = response.data.example;

      typeFeedback(`${prepend}${exampleSolution}${append}`, "", submittedIdRef.current);
      setLoading(false);
      setFocusTrigger((prev) => prev + 1);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching the example solution:", error);
      addNotice({
        type: "error",
        message: "Error fetching the example solution. Please try again.",
      });
    }
  };

  const calculateCategoryProgress = useCallback(() => {
    if (content && content.category && categories[content.category._id] && categories[content.category._id].questions) {
      const category = categories[content.category._id];
      const { questions } = category;
      const totalQuestions = questions.length;
      const answeredQuestions = questions.map((q) => q.isCorrectlyAnswered);
      const totalCorrectlyAnswered = answeredQuestions.filter(Boolean).length;
      return {
        progress: (totalCorrectlyAnswered / totalQuestions) * 100,
        totalQuestions,
        answeredQuestions,
      };
    }
    return { progress: 0, totalQuestions: 0, answeredQuestions: [] };
  }, [content, categories]);

  const throwConfetti = (id) => {
    const rect = document.getElementById(id).getBoundingClientRect();
    const x = rect.left + rect.width / 2;
    const y = rect.top + rect.height / 2;

    // eslint-disable-next-line no-undef
    confetti({
      particleCount: 75,
      startVelocity: 15,
      spread: 360,
      origin: { x: x / window.innerWidth, y: y / window.innerHeight },
      decay: 0.9,
      gravity: 0.3,
      zIndex: 1051,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getConsecutiveDates = async (overrideDate = 5) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const currentDate = new Date();
      const futureDate = new Date(currentDate.getTime() + overrideDate * 24 * 60 * 60 * 1000);

      const payload = { overrideDate: futureDate.toISOString() };
      const datestreakResponse = await axios.post(`${process.env.REACT_APP_API_URL}/datesstreak`, payload, config);
      const consecutiveResponse = datestreakResponse.data.consecutive;

      setCounter(consecutiveResponse);
    } catch (err) {
      console.error(err);
    }
  };

  const getDbConsecutiveDates = async () => {
    const datesStreak = await userData.streakDates;
    try {
      let consecutiveStreak = 0;
      let prevDate = null;
      const today = new Date();

      for (let i = datesStreak.length - 1; i >= 0; i--) {
        const currentDate = new Date(datesStreak[i]);

        if (prevDate === null) {
          // Check if the last streak date is more than 1 day ago
          const daysSinceLastStreak = Math.ceil((today - currentDate) / (1000 * 60 * 60 * 24));
          // toggle above / below to test streakDates
          // const daysSinceLastStreak = 5;
          if (daysSinceLastStreak > 1) {
            consecutiveStreak = 0;
            break; // Break immediately, keeping consecutiveStreak at 0
          }
        }

        if (prevDate === null || isContinuousDay(prevDate, currentDate)) {
          consecutiveStreak++;
        } else {
          break;
        }

        prevDate = currentDate;
      }

      setConsecutiveValue(consecutiveStreak);
    } catch (err) {
      console.error(err);
    }
  };

  const isContinuousDay = (date1, date2) => {
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysDiff === 1;
  };

  const { setCounter } = useConsecutiveContext();

  const consecutiveContext = consecutiveValue;

  const handleSubmit = useCallback(async () => {
    if (!enableCodeSubmit) return;

    const currentOperationId = generateUniqueId();
    submittedIdRef.current = currentOperationId;

    setIsCorrect("");
    setLoading(true);
    clearFeedback();
    getConsecutiveDates();

    // Assistant response
    try {
      const response = await submitCode(courseId, code, currentQuestionIndex, userData.sessionToken);

      if (currentOperationId !== submittedIdRef.current) {
        return;
      }

      const { isCorrect, feedback, isExceptional } = response;

      const syntax = isCorrect ? "// ✓" : "// ✗";
      const codeResponseText = `${syntax} ${feedback}`;
      const analysisResponseText = `${syntax} ${feedback}`;

      let responseText = ["/* ---------- */", "/* EVALUATION */", "/* ---------- */", "\n"].join("\n");

      if (content.genre === "code") {
        responseText += codeResponseText;
      } else if (content.genre === "analysis") {
        responseText += analysisResponseText;
      }

      typeFeedback(responseText, "", currentOperationId);
      setIsCorrect(isCorrect);
      setisExceptional(isExceptional);

      let sound;

      if (isExceptional) {
        sound = exceptionalSound;
      } else if (isCorrect) {
        sound = correctSound;
      } else {
        sound = incorrectSound;
      }

      const audio = new Audio(sound);
      audio.volume = 0.5;
      audio.play();

      if (isCorrect && !content.isCorrectlyAnswered) {
        updateQuestionAsAnswered(currentQuestionIndex);
      }
    } catch (error) {
      // TODO: Need to handle this error better
      if (error.response.data === "Too many submissions made.") {
        addNotice({
          type: "error",
          message: "You reached your submission limit.",
        });
      }
    }

    setLoading(false);
  }, [
    courseId,
    code,
    currentQuestionIndex,
    setLoading,
    content,
    setIsCorrect,
    updateQuestionAsAnswered,
    typeFeedback,
    addNotice,
    getConsecutiveDates,
    enableCodeSubmit,
    userData.sessionToken,
  ]);

  const handleCtrlEnter = useCallback(() => {
    if (isCorrect === true) {
      handleQuestionNavigation("next");
    } else if (enableCodeSubmit) {
      handleSubmit();
    }
  }, [isCorrect, enableCodeSubmit, handleSubmit, handleQuestionNavigation]);

  const wrapInCodeTag = (str) => {
    const parts = str.split(/(`[^`]+`)/g);
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        return <code key={index}>{part.slice(1, -1)}</code>;
      }
      return part;
    });
  };

  useEffect(() => {
    if (content && isOpen) {
      checkIfUserHasAccess(content);
    }
  }, [checkIfUserHasAccess, content, isOpen]);

  useEffect(() => {
    setCode(initialCode);
  }, [initialCode, currentQuestionIndex]);

  useEffect(() => {
    if (isOpen) {
      disableScroll();
      setIsCorrect("");
      clearFeedback();
      submittedIdRef.current = null;
    }
    return () => {
      if (isOpen) {
        enableScroll();
      }
    };
  }, [isOpen]);

  useEffect(() => {
    if (content && content.category) {
      setCategoryProgress(calculateCategoryProgress);
    }
  }, [categories, content, calculateCategoryProgress]);

  useEffect(() => {
    if (isExceptional) {
      throwConfetti("userEditor");
    }
  }, [isExceptional]);

  useEffect(() => {
    if (userData) {
      getDbConsecutiveDates();
    }
  });

  useEffect(() => {
    setCounter(consecutiveContext);
  }, [setCounter, consecutiveContext]);

  if (!isOpen) return null;

  const categoryTitle = content.category.title;

  const { question } = content;

  const handleReportSubmit = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${userData.sessionToken}`,
      },
    };

    const questionIndex = currentQuestionIndex;
    const userAnswer = code;
    const aiResponse = feedback.replace(/\/\*[-\s]*\*\/\s*\/\*\s*ASSISTANT\s*\*\/\s*\/\*[-\s]*\*\//g, "").trim();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/reportanissue`,
        { questionIndex, userAnswer, aiResponse },
        config
      );
      if (response.status === 201) {
        addNotice({
          type: "success",
          message: "Thanks for the feedback. We use this information to improve our model.",
        });
        setReportSubmitted(true);
      } else {
        addNotice({
          type: "error",
          message: "Failed to submit your report. Please try again.",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div id="questionModal">
      <div id="questionModalTopNav">
        <div id="questionModalTopNavLeft">
          <button data-testid="closeQuestion" id="closeQuestionButton" className="button-icon" onClick={closeQuestionModal}>
            <FontAwesomeIcon icon={icon({ name: "xmark", style: "regular" })} className="icon" />
          </button>
          <h2>{categoryTitle}</h2>
        </div>
        <div id="questionModalTopNavCentre">
          <button
            onClick={() => handleQuestionNavigation("previous")}
            className="navigationButton button-no-border"
            disabled={loading}
          >
            <FontAwesomeIcon icon={icon({ name: "chevron-left", style: "regular" })} className="icon" />
          </button>
          <div id="categoryProgress">
            {Object.values(categories[content.category._id]?.questions || []).map((question) => (
              <div
                key={question._id}
                className={`questionSection ${question.isCorrectlyAnswered ? "completed" : ""} ${
                  question.index === currentQuestionIndex ? "current" : ""
                }`}
                style={{ width: `${100 / categoryProgress.totalQuestions}%` }}
              />
            ))}
          </div>
          <button
            onClick={() => handleQuestionNavigation("next")}
            className="navigationButton button-no-border"
            disabled={loading}
          >
            <FontAwesomeIcon icon={icon({ name: "chevron-right", style: "regular" })} className="icon" />
          </button>
        </div>
        <div id="questionModalTopNavRight">
          {isCorrect !== true && (
            <>
              <button
                id="exampleSolutionButton"
                className={`button-icon ${isCorrect === false ? "wiggle" : ""}`}
                onClick={handleExampleSolution}
                data-tooltip-id="exampleSolutionTooltip"
                data-tooltip-content="See example solution"
              >
                <FontAwesomeIcon icon={icon({ name: "lightbulb", style: "regular" })} className="icon" />
              </button>
              <Tooltip id="exampleSolutionTooltip" className="tooltip" place="bottom" />
            </>
          )}
          <button
            id="resetQuestionButton"
            className="button-icon"
            onClick={resetModal}
            data-tooltip-id="resetQuestionTooltip"
            data-tooltip-content="Reset code editor"
          >
            <FontAwesomeIcon icon={icon({ name: "arrow-rotate-left", style: "regular" })} className="icon" />
          </button>
          <Tooltip id="resetQuestionTooltip" className="tooltip" place="bottom" />
        </div>
      </div>
      <div id="questionModalInner">
        <div id="modalContent">
          <h3>{wrapInCodeTag(question)}</h3>
          <div className="codeEditorWrapper">
            {content.genre === "analysis" && content.startingCode && (
              <CodeEditor
                className="startingCodeEditor"
                code={content.startingCode}
                setCode={() => {}}
                isEditable={false}
              />
            )}
            <CodeEditor
              id="userEditor"
              data-testid="userEditor"
              className={`userEditor ${content.genre}Editor`}
              code={code}
              feedback={feedback}
              setCode={setCode}
              currentQuestionIndex={currentQuestionIndex}
              handleCtrlEnter={handleCtrlEnter}
              isEditable={!loading}
              focusTrigger={focusTrigger}
            />
            {feedback && (
              <>
                <CodeEditor className="assistantEditor" code={feedback} setCode={() => {}} isEditable={false} />
                {displayfeedbackButton && !isCorrect && (
                  <div id="assistantButtons">
                    <button data-testid="clearResponse" id="clearFeedbackButton" onClick={clearFeedback}>
                      <FontAwesomeIcon icon={icon({ name: "broom-wide", style: "regular" })} className="icon" />
                      Clear Response
                    </button>
                    <button
                      data-testid="reportBtn"
                      id="reportResponseButton"
                      onClick={handleReportSubmit}
                      className={reportSubmitted ? "reported" : ""}
                    >
                      <FontAwesomeIcon icon={icon({ name: "thumbs-down", style: "regular" })} className="icon" />
                      Report
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
          <div id="shortcutIndicator" className={`${enableCodeSubmit ? "highlighted" : ""}`}>
            Use <code>ctrl + enter</code> to {isCorrect === true ? "continue" : "submit"}
          </div>
          <div id="questionSubmitButtonContainer">
            {isCorrect !== true && (
              <button
                onClick={handleSubmit}
                className={`button-primary-fill ${!enableCodeSubmit ? "disabled" : ""}`}
                disabled={loading}
              >
                <FontAwesomeIcon icon={icon({ name: "arrow-right", style: "regular" })} />
                Submit
                {loading && <FontAwesomeIcon icon={icon({ name: "spinner-third", style: "regular" })} spin />}
              </button>
            )}
            {isCorrect === true && (
              <button className="button-success-fill" onClick={() => handleQuestionNavigation("next")}>
                <FontAwesomeIcon icon={icon({ name: "arrow-right", style: "regular" })} />
                Next Question
              </button>
            )}
          </div>
          {userData.isAdmin && (
            <QuestionAndAnswerEdit
              questionId={content._id}
              initialQuestion={question}
              initialAnswer={content.startingCode || ""}
              token={userData.sessionToken}
              userData={userData}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionModal;
