import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo, useState } from "react";
import SubscriptionHandler from "../../components/SubscriptionHandler";

import Category from "../../components/Category/Category";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header";
import LoadingSkeleton from "../../components/LoadingSkeleton";
import QuestionModal from "../../components/QuestionModal/QuestionModal";
import { useModal } from "../../context/ModalContext";
import { useNotice } from "../../context/NoticeContext";
import { fetchCategories, fetchCourses } from "../../utils/apiCalls";
import "./questions.min.css";

const Questions = ({ userData, setUserData }) => {
  const [courseOptions, setCourseOptions] = useState([]);
  const [categories, setCategories] = useState({});
  const [expandedCategories, setExpandedCategories] = useState({});
  const [selectedCourse, setSelectedCourse] = useState({});
  const [questionContent, setQuestionContent] = useState(null);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [lastExpanded, setLastExpanded] = useState(null);
  const [token, setToken] = useState(null);

  const { addNotice } = useNotice();
  const { openModal, closeModal } = useModal();

  const { handleSubscribe } = SubscriptionHandler({ userData });

  const openSubscribeModal = useCallback(() => {
    openModal("subscribeModal", {
      title: "CodeAcer Premium: Complete Access",
      content: (
        <div className="modal-body-row">
          <p>Enhance your expertise with full access to tasks and no rate limits.</p>
          <ul>
            <li>
              <FontAwesomeIcon icon={icon({ name: "star", style: "solid" })} className="icon" />
              Unlock all tasks
            </li>
            <li>
              <FontAwesomeIcon icon={icon({ name: "star", style: "solid" })} className="icon" />
              No rate limits
            </li>
          </ul>
          <button className="button-large" onClick={() => handleSubscribe("monthly")}>
            Subscribe for $7.99/month
          </button>
          <button className="button-large" onClick={() => handleSubscribe("annual")}>
            Subscribe for $47.99/year <small>(save 50%)</small>
          </button>
        </div>
      ),
    });
  }, [openModal, handleSubscribe]);

  const updateQuestionAsAnswered = useCallback((questionIndex) => {
    setCategories((prevCategories) => {
      const categoryId = Object.keys(prevCategories).find((id) =>
        prevCategories[id].questions.some((q) => q.index === questionIndex)
      );
      if (categoryId) {
        const updatedCategory = {
          ...prevCategories[categoryId],
          questions: prevCategories[categoryId].questions.map((q) =>
            q.index === questionIndex ? { ...q, isCorrectlyAnswered: true } : q
          ),
        };
        return { ...prevCategories, [categoryId]: updatedCategory };
      }
      return prevCategories;
    });
  }, []);

  const handleCourseChange = useCallback(
    (e) => {
      const selectedId = e.target.dataset.value;
      const newSelectedCourse = courseOptions.find((course) => course.id === selectedId);
      setSelectedCourse(newSelectedCourse || {});

      if (newSelectedCourse) {
        localStorage.setItem("selectedCourseId", newSelectedCourse.id);
      }
    },
    [courseOptions]
  );

  const findQuestionByIndex = useCallback(
    (index) => {
      for (const category of Object.values(categories)) {
        const question = category.questions.find((q) => q.index === index);
        if (question) return question;
      }
      return null;
    },
    [categories]
  );

  const goToQuestion = useCallback(
    (direction) => {
      setQuestionIndex((prevIndex) => {
        const currentQuestion = findQuestionByIndex(prevIndex);
        if (currentQuestion) {
          const currentCategoryQuestions = categories[currentQuestion.category._id].questions;
          const currentIndexInCategory = currentCategoryQuestions.findIndex((q) => q.index === prevIndex);

          let newIndex;
          if (direction === "next") {
            const nextIndexInCategory = currentIndexInCategory + 1;
            if (nextIndexInCategory < currentCategoryQuestions.length) {
              newIndex = currentCategoryQuestions[nextIndexInCategory].index;
            } else {
              // If we are at the end of the current category, find the next category with questions
              const categoryIds = Object.keys(categories);
              const currentCategoryIndex = categoryIds.indexOf(currentQuestion.category._id);
              let nextCategoryIndex = currentCategoryIndex + 1;
              while (nextCategoryIndex < categoryIds.length) {
                const nextCategoryQuestions = categories[categoryIds[nextCategoryIndex]].questions;
                if (nextCategoryQuestions.length > 0) {
                  newIndex = nextCategoryQuestions[0].index;
                  break;
                }
                nextCategoryIndex++;
              }
              // If we reached the end of all categories, go to the first question of the first category
              if (newIndex === undefined) {
                const firstCategoryId = categoryIds[0];
                const firstCategory = categories[firstCategoryId];
                if (firstCategory && firstCategory.questions.length > 0) {
                  newIndex = firstCategory.questions[0].index;
                }
              }
            }
          } else if (direction === "previous") {
            const prevIndexInCategory = currentIndexInCategory - 1;
            if (prevIndexInCategory >= 0) {
              newIndex = currentCategoryQuestions[prevIndexInCategory].index;
            } else {
              // If we are at the beginning of the current category, find the previous category with questions
              const categoryIds = Object.keys(categories);
              const currentCategoryIndex = categoryIds.indexOf(currentQuestion.category._id);
              let prevCategoryIndex = currentCategoryIndex - 1;
              while (prevCategoryIndex >= 0) {
                const prevCategoryQuestions = categories[categoryIds[prevCategoryIndex]].questions;
                if (prevCategoryQuestions.length > 0) {
                  newIndex = prevCategoryQuestions[prevCategoryQuestions.length - 1].index;
                  break;
                }
                prevCategoryIndex--;
              }
              // If we reached the beginning of all categories, go to the last question of the last category
              if (newIndex === undefined) {
                const lastCategoryId = categoryIds[categoryIds.length - 1];
                const lastCategory = categories[lastCategoryId];
                if (lastCategory && lastCategory.questions.length > 0) {
                  newIndex = lastCategory.questions[lastCategory.questions.length - 1].index;
                }
              }
            }
          }

          return newIndex !== undefined ? newIndex : prevIndex;
        }
        return prevIndex; // No current question found, stay at the current index
      });
    },
    [categories, findQuestionByIndex]
  );

  const toggleCategoryExpanded = useCallback((categoryId) => {
    setExpandedCategories((prev) => {
      const newState = !prev[categoryId];
      let newExpandedCategories = {};

      for (let id in prev) {
        newExpandedCategories[id] = false;
      }

      if (newState) {
        newExpandedCategories[categoryId] = true;
        setLastExpanded(categoryId);
      }

      return newExpandedCategories;
    });
  }, []);

  const checkIfUserHasAccess = useCallback(
    (content) => {
      const isPremiumCategory = content.category.premium;
      const hasAccess = userData.isPremium || !isPremiumCategory;

      if (!hasAccess) {
        openSubscribeModal();
        return false;
      }

      return true;
    },
    [userData, openSubscribeModal]
  );

  const isMobileDevice = useCallback(() => {
    return window.innerWidth <= 768;
  }, []);

  const defineStartingCode = useMemo(
    () => (content) => {
      if (!content) return ""; // Return an empty string or some default code if content is not available

      if (content.genre === "code") {
        const spacing = content.startingCode ? "\n\n" : "";
        return `${content.startingCode}${spacing}`;
      }
    },
    []
  );

  const openQuestionModal = useCallback(
    (content) => {
      if (!checkIfUserHasAccess(content)) {
        return;
      }

      if (isMobileDevice()) {
        addNotice({
          type: "error",
          message: "CodeAcer is best experienced on desktop. Please switch to a desktop device to continue.",
        });
        return;
      }

      setQuestionIndex(content.index);
      setQuestionContent(content);
      openModal("questionModal", {
        content: (
          <QuestionModal
            courseId={selectedCourse.id}
            closeQuestionModal={closeModal}
            content={content}
            defineStartingCode={defineStartingCode}
            currentQuestionIndex={content.index}
            goToQuestion={goToQuestion}
            updateQuestionAsAnswered={updateQuestionAsAnswered}
            categories={categories}
            loading={loading}
            setLoading={setLoading}
            checkIfUserHasAccess={checkIfUserHasAccess}
            userData={userData}
          />
        ),
      });
    },
    [
      checkIfUserHasAccess,
      isMobileDevice,
      addNotice,
      openModal,
      closeModal,
      selectedCourse.id,
      defineStartingCode,
      goToQuestion,
      updateQuestionAsAnswered,
      categories,
      loading,
      setLoading,
      userData,
    ]
  );

  const fetchAndSetCategories = useCallback(async () => {
    setLoading(true);
    if (userData && userData.sessionToken && selectedCourse && selectedCourse.id) {
      try {
        const fetchedCategories = await fetchCategories(userData.sessionToken, selectedCourse.id);
        setCategories(fetchedCategories);
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [userData, selectedCourse]);

  useEffect(() => {
    const userString = localStorage.getItem("user");
    if (userString) {
      try {
        const parsedUserData = JSON.parse(userString);
        const parsedToken = parsedUserData.token;
        setToken(parsedToken);
      } catch (error) {
        console.error("Error parsing user data:", error);
      }
    }
  }, [loading, userData]);

  useEffect(() => {
    const savedCourseId = localStorage.getItem("selectedCourseId");

    const fetchAndSetCourses = async () => {
      setLoading(true);
      if (token) {
        try {
          const fetchedCourses = await fetchCourses(token);
          if (fetchedCourses && Array.isArray(fetchedCourses)) {
            const formattedCourses = fetchedCourses.map((course) => ({
              id: course._id,
              title: course.title,
              image: course.image,
            }));

            setCourseOptions(formattedCourses);

            if (savedCourseId) {
              const savedCourse = formattedCourses.find((course) => course.id === savedCourseId);
              setSelectedCourse(savedCourse || formattedCourses[0] || {});
            } else {
              setSelectedCourse(formattedCourses[0] || {});
            }
          }
        } catch (error) {
          console.error("Failed to fetch courses:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchAndSetCourses();
  }, [token]);

  useEffect(() => {
    if (token && selectedCourse && selectedCourse.id) {
      fetchAndSetCategories();
    }
  }, [token, selectedCourse, fetchAndSetCategories]);

  useEffect(() => {
    if (questionContent && questionIndex === questionContent.index) {
      return;
    }

    const newQuestionContent = findQuestionByIndex(questionIndex);
    if (newQuestionContent) {
      setQuestionContent(newQuestionContent);
    }
  }, [questionIndex, findQuestionByIndex, questionContent]);

  useEffect(() => {
    if (lastExpanded) {
      const element = document.getElementById(lastExpanded);
      if (element) {
        const y = element.getBoundingClientRect().top + window.scrollY - 64;
        window.scrollTo({ top: y });
      }
    }
  }, [lastExpanded]);

  const categoriesArray = useMemo(() => Object.entries(categories), [categories]);

  return (
    <div id="questionsContainer">
      <div id="questionsContainerInner">
        <Header
          selectedCourse={selectedCourse}
          courseOptions={courseOptions}
          handleCourseChange={handleCourseChange}
          userData={userData}
          setUserData={setUserData}
          openSubscribeModal={openSubscribeModal}
        />
        {loading ? (
          <LoadingSkeleton />
        ) : (
          <>
            <div id="categoriesContainer">
              {categoriesArray.map(([categoryId, categoryData]) => (
                <Category
                  key={categoryId}
                  categoryId={categoryId}
                  categoryData={categoryData}
                  expandedCategories={expandedCategories}
                  toggleCategoryExpanded={toggleCategoryExpanded}
                  openQuestionModal={openQuestionModal}
                  categoryIndex={categoryData.index}
                  token={token}
                  userData={userData}
                />
              ))}
            </div>
            <Footer />
          </>
        )}
      </div>
    </div>
  );
};

export default Questions;
