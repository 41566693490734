import axios from "axios";
import React, { useState } from "react";

const QuestionAndAnswerEdit = ({ questionId, token }) => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updateData = {};
    if (question) updateData.question = question;
    if (answer) updateData.answer = answer;

    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/admineditquestion/${questionId}`, updateData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log("Update successful:", response.data); // TODO: Replace with notice
      setQuestion("");
      setAnswer("");
    } catch (error) {
      console.error("Error updating question:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Edit question (leave blank to keep current)"
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
      />
      <input
        type="text"
        placeholder="Edit answer (leave blank to keep current)"
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
      />
      <button type="submit">Update</button>
    </form>
  );
};

export default QuestionAndAnswerEdit;
