import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo-small.png";
import GitHubLogin from "../../components/GithubLogin";
import GoogleLogin from "../../components/GoogleLogin";
import { useNotice } from "../../context/NoticeContext";
import "./authentication.min.css";

import { registrationSchema } from "../../validation/validation";

const Index = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [password, setPassword] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const { addNotice } = useNotice();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await registrationSchema.validate({ email, firstName, password }, { abortEarly: false });

      setValidationErrors({});

      const cleanedEmail = email.trim();

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, {
        email: cleanedEmail,
        firstName,
        password,
      });

      if (response.status === 201 || response.status === 200) {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
          email,
          password,
        });

        const userObject = response.data;

        localStorage.setItem("user", JSON.stringify(userObject));

        navigate("/home");
      }
    } catch (err) {
      if (err.name === "ValidationError") {
        const errors = {};
        err.inner.forEach((error) => {
          errors[error.path] = error.message;
          addNotice({
            type: "error",
            message: error.message,
          });
        });
        setValidationErrors(errors);
      } else if (err.response && err.response.data && err.response.data.error) {
        const errorMessage = err.response.data.error;
        if (errorMessage === "Email already exists") {
          addNotice({
            type: "error",
            message: "Email already exists",
          });
        } else if (
          errorMessage ===
          "Registration successful, but failed to trigger confirmation email. Please try click on the button to trigger the email again."
        ) {
          addNotice({
            type: "warning",
            message:
              "Registration successful, but there was an issue sending the confirmation email. Please try again later.",
          });
        } else {
          addNotice({
            type: "error",
            message: errorMessage,
          });
        }
      } else {
        console.error(err);
        addNotice({
          type: "error",
          message: "An unexpected error occurred. Please try again.",
        });
      }
    }
  };

  const setUserEmailValue = (e) => {
    e.preventDefault();
    const value = e.target.value.replace(/\s/g, ""); // Remove all whitespace immediately
    setEmail(value);
  };

  const handleInvalid = (event) => {
    event.preventDefault();
    if (!event.target.value) {
      setValidationErrors((prev) => ({
        ...prev,
        email: "Email is required",
      }));
    } else if (!/\S+@\S+\.\S+/.test(event.target.value)) {
      setValidationErrors((prev) => ({
        ...prev,
        email: "Please enter a valid email address",
      }));
    } else {
      setValidationErrors((prev) => {
        const { email, ...rest } = prev;
        return rest;
      });
    }
  };

  return (
    <div className="authenticationContainer">
      <a href="/">
        <img id="logo" src={logo} alt="Logo" />
      </a>
      <div className="authenticationContainerInner">
        <h1>Create your account</h1>
        <form noValidate onSubmit={handleSubmit} autoComplete="off">
          <input
            data-testid="email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={setUserEmailValue}
            onInvalid={handleInvalid}
            autoComplete="new-email"
            className={validationErrors.email ? "error" : ""}
          />
          <input
            data-testid="firstName"
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            autoComplete="new-username"
            className={validationErrors.firstName ? "error" : ""}
          />
          <input
            data-testid="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="new-password"
            className={validationErrors.password ? "error" : ""}
          />
          <button data-testid="signup" type="submit" className="button-large button-primary-fill">
            Sign up
          </button>
        </form>
        <p className="accessControl">
          <span>
            Already have an account? <a href="/login">Log in</a>
          </span>
        </p>
        <div id="or">
          <span></span>
        </div>
        <div id="continueWithThirdPartiesContainer">
          <GoogleLogin />
          <GitHubLogin />
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Index;
