import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./notice.min.css";

const Notice = ({ id, type, message, removeNotice }) => (
  <div className={`notice ${type}`} key={id}>
    {message}
    <FontAwesomeIcon icon={icon({ name: "xmark", style: "regular" })} className="close" onClick={() => removeNotice(id)} />
  </div>
);

export default Notice;
