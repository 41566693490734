import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModal } from "../context/ModalContext";
import { useNotice } from "../context/NoticeContext";
import socket from "../socket";
import Checkout from "./Checkout";
import Dropdown from "./Dropdown/Dropdown";

const UserProfileDropdown = ({ userData, setUserData, openSubscribeModal }) => {
  const [deletingUser, setDeletingUser] = useState(false);
  const [userFirstName, setUserFirstName] = useState(null);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const firstNameRef = useRef(null);
  const { addNotice } = useNotice();
  const { openModal, closeModal } = useModal();

  useEffect(() => {
    const userString = localStorage.getItem("user");
    if (userString) {
      try {
        const parsedUserData = JSON.parse(userString);
        const parsedSessionToken = parsedUserData.token;
        setToken(parsedSessionToken);
        if (parsedUserData.firstName) {
          setUserFirstName(parsedUserData.firstName);
        }
      } catch (error) {
        console.error("Error parsing user data:", error);
      }
    }
  }, []);

  const confirmDeleteUser = useCallback(async () => {
    setDeletingUser(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/deleteuser`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );
      addNotice({
        type: "success",
        message: "Successfully deleted account",
      });
      localStorage.removeItem("user");
      navigate("/");
    } catch (error) {
      console.error("Error deleting user:", error);
      addNotice({
        type: "error",
        message: "Failed to delete account. Please try again.",
      });
    } finally {
      setDeletingUser(false);
      closeModal();
    }
  }, [token, addNotice, navigate, closeModal]);

  const handleDeleteUser = useCallback(() => {
    openModal("deleteUser", {
      title: "Confirm",
      content: (
        <>
          <div className="modal-body-row">
            <p>Are you sure you want to delete your account? This action cannot be undone.</p>
          </div>
          <div className="modal-body-row modal-body-row-buttons">
            <button className="button-no-border" onClick={closeModal}>
              Cancel
            </button>
            <button
              data-testid="confirmUserDelete"
              className={`button-danger-fill ${deletingUser ? "button-disabled" : ""}`}
              onClick={confirmDeleteUser}
            >
              Delete
              {deletingUser && <FontAwesomeIcon icon={icon({ name: "circle-notch", style: "regular" })} spin />}{" "}
            </button>
          </div>
        </>
      ),
    });
  }, [openModal, closeModal, deletingUser, confirmDeleteUser]);

  const handleLogout = useCallback(async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );
      localStorage.removeItem("user");
      navigate("/");
    } catch (err) {
      console.error(err);
      addNotice({
        type: "error",
        message: "Failed to logout. Please try again.",
      });
    }
  }, [token, navigate, addNotice]);

  const onSaveFirstName = useCallback(
    async (e) => {
      e.preventDefault();
      const trimmedName = firstNameRef.current.value.trim();
      const formattedFirstName = trimmedName
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ");

      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/updatefirstname`,
          { firstName: formattedFirstName },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );

        if (response.status === 200) {
          setUserFirstName(formattedFirstName);
          closeModal();
          addNotice({
            type: "success",
            message: `Welcome to CodeAcer, ${formattedFirstName}!`,
          });
        }
      } catch (error) {
        addNotice({
          type: "error",
          message: "Failed to update first name. Please try again.",
        });
      }
    },
    [token, closeModal, addNotice]
  );

  const getUserName = useCallback(() => {
    if (userData && !userData.firstName) {
      openModal("updateFirstName", {
        title: "What's your first name?",
        content: (
          <div className="modal-body-row">
            <form onSubmit={onSaveFirstName}>
              <input type="text" ref={firstNameRef} placeholder="First name" />
              <button type="submit">Save</button>
            </form>
          </div>
        ),
        canBeClosed: false,
      });
    }
  }, [userData, openModal, onSaveFirstName]);

  useEffect(() => {
    getUserName();
  }, [getUserName]);

  useEffect(() => {
    if (userData && token) {
      const handlePaymentSuccess = async () => {
        try {
          const userResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/userislogged`,
            {},
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setUserData(userResponse.data.user);
        } catch (err) {
          console.error("Error fetching user data:", err);
        }
      };

      socket.on("paymentSuccess", handlePaymentSuccess);

      return () => {
        socket.off("paymentSuccess", handlePaymentSuccess);
      };
    }
  }, [userData, token, setUserData]);

  const openSettingsModal = useCallback(() => {
    openModal("settings", {
      title: "Account",
      content: (
        <>
          <div className="modal-body-row">
            <div>Email</div>
            <div>{userData.email}</div>
          </div>
          <Checkout
            userData={userData}
            token={token}
            setUserData={setUserData}
            setModalContent={closeModal}
            openSubscribeModal={openSubscribeModal}
          />
          <div className="modal-body-row">
            <div>Reset password</div>
            <a className="button" href="/forgot">
              Reset
            </a>
          </div>
          <div className="modal-body-row">
            <div>Permanently delete account</div>
            <button data-testid="deleteUser" className="button-danger-fill" onClick={handleDeleteUser}>
              Delete
            </button>
          </div>
        </>
      ),
    });
  }, [openModal, userData, token, setUserData, closeModal, openSubscribeModal, handleDeleteUser]);

  return (
    <Dropdown
      id="userProfileDropdown"
      testId="userProfileBtn"
      buttonContent={
        <>
          {userFirstName || userData?.firstName || "User"}
          {userData.isPremium && <FontAwesomeIcon id="premiumIcon" icon={icon({ name: "crown", style: "solid" })} />}
        </>
      }
    >
      <button data-testid="settings" className="dropdownItem" onClick={openSettingsModal}>
        <FontAwesomeIcon icon={icon({ name: "cog", style: "regular" })} />
        Account
      </button>
      <button data-testid="logout" className="dropdownItem" onClick={handleLogout}>
        <FontAwesomeIcon icon={icon({ name: "arrow-right-from-bracket", style: "regular" })} />
        Logout
      </button>
    </Dropdown>
  );
};

export default UserProfileDropdown;
