import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./pricing.min.css";

const PricingCard = ({ title, price, features, buttonClass }) => (
  <div className="pricingCard">
    <h3>{title}</h3>
    <p className="price">{price}</p>
    <ul>
      {features.map((feature, index) => (
        <li key={index}>
          <FontAwesomeIcon icon={icon({ name: "circle-check", style: "regular" })} className="icon" />
          {feature}
        </li>
      ))}
    </ul>
    <a href="/signup" className={`button button-${buttonClass}`}>
      Sign up
    </a>
  </div>
);

const PricingCards = () => {
  const cards = [
    {
      title: "Free",
      price: "Free for all users",
      features: ["Select coding tasks", "Select analysis tasks", "Access to all skill levels"],
      buttonClass: "primary",
    },
    {
      title: "Premium",
      price: "$7.99/month",
      features: ["Everything in Free", "Access to all tasks", "No rate limits"],
      buttonClass: "yellow",
    },
    {
      title: "Annual",
      price: "$47.99/year",
      features: ["Everything in Premium", "Full access for one year"],
      buttonClass: "yellow",
    },
  ];

  return (
    <div className="pricingCards">
      {cards.map((card, index) => (
        <PricingCard key={index} {...card} />
      ))}
    </div>
  );
};

export default PricingCards;
