import Cookies from "js-cookie";

export const removeCookiesOnLogout = () => {
  Cookies.remove("githubUser");
  Cookies.remove("github-primary-email");
  Cookies.remove("access_token");
};

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(";").shift();
  }
  return null;
};

// TODO: Remove this page, replaced by js-cookie
