import React, { createContext, useEffect, useReducer } from "react";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.payload,
        isLoggedIn: true,
      };
    case "LOGOUT":
      return {
        ...state,
        user: null,
        isLoggedIn: false,
      };
    case "UPDATE_STATUS":
      return {
        ...state,
        user: {
          ...state.user,
          signupStatus: action.payload.signupStatus,
        },
      };
    case "UPDATE_SUBSCRIPTION":
      return {
        ...state,
        user: {
          ...state.user,
          subscribed: action.payload.subscription,
        },
      };

    default:
      return state;
  }
};

export const LoginProvider = ({ children }) => {
  const storedUser = localStorage.getItem("user");
  const initialState = {
    user: null,
    email: null,
    isLoggedIn: false,
  };

  try {
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      initialState.user = parsedUser;
      initialState.email = parsedUser.email;
      initialState.isLoggedIn = true;
    }
  } catch (error) {
    console.error("Error parsing stored user data:", error);
    localStorage.removeItem("user");
  }

  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    try {
      if (state.user) {
        localStorage.setItem("user", JSON.stringify(state.user));
      } else {
        localStorage.removeItem("user");
      }
    } catch (error) {
      console.error("Error storing user data:", error);
    }
  }, [state.user]);

  const isLoggedIn = Boolean(state.isLoggedIn);

  const updateUserSubscription = (subscriptionData) => {
    dispatch({
      type: "UPDATE_SUBSCRIPTION",
      payload: { subscription: subscriptionData },
    });
  };

  return (
    <AuthContext.Provider value={{ ...state, isLoggedIn, dispatch, updateUserSubscription }}>
      {children}
    </AuthContext.Provider>
  );
};
