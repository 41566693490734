import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

// Axios interceptor to handle token expiration
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.config) {
      const originalRequest = error.config;

      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.error === "Token expired" &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        try {
          const response = await instance.post("/refreshtoken", null, {
            withCredentials: true,
          });

          const newToken = response.data.token;

          const user = JSON.parse(localStorage.getItem("user"));
          const updatedUser = {
            ...user,
            token: newToken,
          };
          localStorage.setItem("user", JSON.stringify(updatedUser));
          if (originalRequest.headers) {
            originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
          } else {
            originalRequest.headers = {
              Authorization: `Bearer ${newToken}`,
            };
          }
          return instance(originalRequest);
        } catch (refreshError) {
          console.error("Failed to refresh token", refreshError);
        }
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
