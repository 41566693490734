import axios from "axios";
import React, { useState } from "react";
import logo from "../../assets/images/logo-small.png";
import { useNotice } from "../../context/NoticeContext";
import "./authentication.min.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const { addNotice } = useNotice();

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/forgotpassword`, { email });
      addNotice({ type: "success", message: response.data });
    } catch (err) {
      addNotice({ type: "error", message: err.response.data });
    }
  };

  return (
    <div className="authenticationContainer">
      <a href="/">
        <img id="logo" src={logo} alt="Logo" />
      </a>
      <div className="authenticationContainerInner">
        <h1>Reset your password</h1>
        <h2>Please enter your email address to receive password reset instructions</h2>
        <form onSubmit={handleResetPassword}>
          <input
            data-testid="forgotPasswordEmail"
            type="email"
            placeholder="Your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button
            data-testid="submitPasswordReset"
            type="submit"
            className="button-large button-primary button-primary-fill"
          >
            Continue
          </button>
        </form>
      </div>
      <div></div>
    </div>
  );
};

export default ForgotPassword;
