import React, { createContext, useState, useContext } from "react";

const ConsecutiveContext = createContext();

export const ConsecutiveProvider = ({ children }) => {
  const [counter, setCounter] = useState(0);

  return <ConsecutiveContext.Provider value={{ counter, setCounter }}>{children}</ConsecutiveContext.Provider>;
};

// Custom hook to use the context
export const useConsecutiveContext = () => useContext(ConsecutiveContext);
