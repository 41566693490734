import "./footer.min.css";

const Footer = (includeLogin = true) => {
  return (
    <div className="footer">
      <a href="mailto:hello@codeacer.com">Contact</a>
      <a href="/terms">Terms</a>
      <a href="/privacy">Privacy</a>
    </div>
  );
};

export default Footer;
