import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import google from "../assets/images/google.png";
import { useNotice } from "../context/NoticeContext";
import useAuthContext from "../hooks/useAuthContext";

const GoogleLogin = () => {
  const [googleUser, setGoogleUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const { addNotice } = useNotice();
  const navigate = useNavigate();
  const { dispatch } = useAuthContext();

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setGoogleUser(tokenResponse);
      checkUserExists(tokenResponse.access_token);
    },
    onError: (error) => {
      console.error("Login Failed:", error);
      addNotice({
        type: "error",
        message: "Login failed. Please try again.",
      });
    },
  });

  const checkUserExists = async (accessToken) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/checkuserexists`,
        {
          type: "google",
          token: accessToken,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          withCredentials: true,
        }
      );

      if (response.data && response.data.exists) {
        const { sessionToken, refreshToken, email, firstName } = response.data;
        const userObject = {
          token: sessionToken,
          refreshToken,
          email,
          firstName,
          signupStatus: "active",
        };

        Cookies.set("sessionToken", sessionToken, {
          sameSite: "strict",
          secure: true,
        });
        Cookies.set("refreshToken", refreshToken, {
          sameSite: "strict",
          secure: true,
        });
        Cookies.set("user", JSON.stringify(userObject), {
          sameSite: "strict",
          secure: true,
        });

        dispatch({
          type: "LOGIN",
          payload: userObject,
        });

        navigate("/home");
      }
    } catch (error) {
      console.error("API call failed", error);
      addNotice({
        type: "error",
        message: "Login failed. Please try again.",
      });
    }
  };

  return (
    <button onClick={() => googleLogin()} data-testid="googleLogin" className="button-large">
      <img src={google} alt="Google Logo" />
      Continue with Google
    </button>
  );
};

export default GoogleLogin;
