import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { React } from "react";

const Question = ({ question, openQuestionModal, userData }) => {
  const isPremiumUser = userData.isPremium;
  const isQuestionPremium = question.premium;

  const showQuestionProgress = question.isCorrectlyAnswered;
  const showQuestionAccessibleStatus = !isPremiumUser && question.isCorrectlyAnswered === false && isQuestionPremium;

  return (
    <li
      className={`questionItem ${showQuestionProgress ? "questionItemAnswered" : ""}`}
      onClick={() => openQuestionModal(question)}
    >
      <div className={`questionInfo`}>
        <div className="questionGenre">
          {question.genre === "code" ? (
            <FontAwesomeIcon icon={icon({ name: "code-simple", style: "regular" })} className="icon" />
          ) : (
            <FontAwesomeIcon icon={icon({ name: "hashtag", style: "regular" })} className="icon" />
          )}
        </div>
        <div className="questionTitle">{question.title}</div>
      </div>
      {showQuestionAccessibleStatus && (
        <FontAwesomeIcon icon={icon({ name: "crown", style: "regular" })} className="icon premiumIcon" />
      )}
      {showQuestionProgress && (
        <div className="questionStatus">
          <FontAwesomeIcon icon={icon({ name: "check", style: "regular" })} className="icon" />
        </div>
      )}
    </li>
  );
};

export default Question;
