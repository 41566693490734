import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import GithubAuthCallback from "./components/GithubAuthCallback";
import Modal from "./components/Modal/Modal";
import NoticeManager from "./components/NoticeManager/NoticeManager";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import UserDataFetcher from "./components/UserDataFetcher";
import { LoginProvider } from "./context/AuthContext";
import { ConsecutiveProvider } from "./context/ConsequitiveContext";
import { ModalProvider } from "./context/ModalContext";
import NoticeProvider from "./context/NoticeContext";
import Confirm from "./pages/Authentication/Confirm";
import ForgotPassword from "./pages/Authentication/Forgot";
import Login from "./pages/Authentication/Login";
import Signup from "./pages/Authentication/Signup";
import UpdatePassword from "./pages/Authentication/UpdatePassword";
import Index from "./pages/Homepage/Homepage";
import Questions from "./pages/Questions/Questions";
import SubThankYou from "./pages/SubThankYou/SubThankYou";
import Facebook from "./tracking/Facebook";

import "./styles/index.min.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const App = () => {
  return (
    <Elements stripe={stripePromise}>
      <LoginProvider>
        <ConsecutiveProvider>
          <ModalProvider>
            <NoticeProvider>
              <Router>
                <NoticeManager />
                <Facebook />
                <Modal />
                <Routes>
                  <Route path="/" element={<Index />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                  <Route path="/terms" element={<TermsOfService />} />
                  <Route
                    path="/home"
                    element={
                      <UserDataFetcher>
                        <Questions />
                      </UserDataFetcher>
                    }
                  />
                  <Route path="/confirm/:token" element={<Confirm />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/forgot" element={<ForgotPassword />} />
                  <Route path="/thanks" element={<SubThankYou />} />
                  <Route path="/updatepassword/:token" element={<UpdatePassword />} />
                  <Route path="/github/callback" element={<GithubAuthCallback />} />
                </Routes>
              </Router>
            </NoticeProvider>
          </ModalProvider>
        </ConsecutiveProvider>
      </LoginProvider>
    </Elements>
  );
};

export default App;
