import React from "react";
import "./Testimonial.min.css";

const Testimonial = ({ avatarSrc, quote, name, position }) => {
  return (
    <div className="testimonial">
      <div className="avatarWrapper">
        <img className="avatar" src={avatarSrc} alt={name} />
      </div>
      <div className="content">
        <div className="quote">"{quote}"</div>
        <div className="author">
          <p className="name">{name}</p>
          <p className="position">{position}</p>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
