// Login.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo-small.png";
import GitHubLogin from "../../components/GithubLogin";
import GoogleLogin from "../../components/GoogleLogin";
import { useNotice } from "../../context/NoticeContext";
import axiosInstance from "../../utils/axiosConfig";
import "./authentication.min.css";

const Index = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});

  const { addNotice } = useNotice();
  const navigate = useNavigate();

  const fetchUserData = async () => {
    if (token) {
      try {
        const userResponse = await axiosInstance.get("/userdata", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const user = userResponse.data;
        const userObject = {
          token,
          ...user,
        };
        localStorage.setItem("user", JSON.stringify(userObject));
        if (user) {
          navigate("/home");
        }
      } catch (err) {
        console.error("Error fetching user data:", err);
        // Handle other errors (e.g., clear user data and redirect to login)
        localStorage.removeItem("user");
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/login", {
        email,
        password,
      });

      if (response.data.authenticated === false) {
        addNotice({
          type: "error",
          message: "Incorrect email or password. Please try again.",
        });
        return;
      }

      const newToken = response.data.token;
      setToken(newToken);
    } catch (err) {
      const backendErrorMessage = err.response?.data?.message;
      if (backendErrorMessage) {
        addNotice({ type: "error", message: backendErrorMessage });
      }
    }
  };

  const setUserEmailValue = (e) => {
    e.preventDefault();
    const value = e.target.value.replace(/\s/g, "");
    setEmail(value);
  };

  const handleInvalid = (event) => {
    event.preventDefault();
    if (!event.target.value) {
      setValidationErrors((prev) => ({
        ...prev,
        email: "Email is required",
      }));
    } else if (!/\S+@\S+\.\S+/.test(event.target.value)) {
      setValidationErrors((prev) => ({
        ...prev,
        email: "Please enter a valid email address",
      }));
    } else {
      setValidationErrors((prev) => {
        const { email, ...rest } = prev;
        return rest;
      });
    }
  };

  return (
    <div className="authenticationContainer">
      <a href="/">
        <img id="logo" src={logo} alt="Logo" />
      </a>
      <div className="authenticationContainerInner">
        <h1>Welcome back</h1>
        <form noValidate onSubmit={handleSubmit}>
          <input
            data-testid="email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={setUserEmailValue}
            onInvalid={handleInvalid}
            autoComplete="new-email"
            className={validationErrors.email ? "error" : ""}
          />

          <input
            data-testid="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button data-testid="submit" type="submit" className="button-large button-primary-fill">
            Login
          </button>
        </form>
        <p className="accessControl">
          <span>
            Don't have an account? <a href="/signup">Sign up</a>
          </span>
          <span>
            Forgot your password?{" "}
            <a data-testid="forgotPassword" href="/forgot">
              Reset
            </a>
          </span>
        </p>
        <div id="or">
          <span></span>
        </div>
        <div id="continueWithThirdPartiesContainer">
          <GoogleLogin />
          <GitHubLogin />
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Index;
