import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import Footer from "../../components/Footer/Footer";
import "./subThankYou.min.css";

const WelcomePage = () => {
  const [customerName, setCustomerName] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const sessionId = new URLSearchParams(location.search).get("session_id");
    if (sessionId) {
      axios
        .get(`/api/verify-session?session_id=${sessionId}`)
        .then((response) => {
          setCustomerName(response.data.customerName);
        })
        .catch((error) => {
          console.error("Error verifying session:", error);
        });
    }

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      confetti({
        particleCount: 75,
        startVelocity: 15,
        spread: 360,
        origin: { x: 0.5, y: 0.5 },
        decay: 0.9,
        gravity: 0.3,
        zIndex: 1051,
      });
    }, 1000);
  }, [location]);

  const handleNavigate = () => {
    navigate("/home");
  };

  return (
    <div id="subThankYouContainer">
      <img src={Logo} id="logo" alt="CodeAcer Logo" onClick={handleNavigate} />
      <div id="subThankYouContainerInner">
        <h1>Thanks for subscribing, {customerName ? customerName : ""}🎉</h1>
        <p>Enjoy unlimited access to all tasks and no rate limits</p>
        <button className="button-large button-primary-fill" onClick={handleNavigate}>
          Start Practicing
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default WelcomePage;
