import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import annualArrowImage from "../../assets/images/homepage/annualArrow.png";
import jsImage from "../../assets/images/homepage/javascript.png";
import lucaAvatar from "../../assets/images/homepage/testimonials/luca.jpeg";
import logo from "../../assets/images/logo.png";
import video from "../../assets/videos/editor.mp4";
import Footer from "../../components/Footer/Footer";
import Pricing from "./Pricing/Pricing";
import Testimonial from "./Testimonial/Testimonial";
import "./homepage.min.css";

const Index = () => {
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRef = useRef(null);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (user) {
      navigate("/home");
    }
  }, [user, navigate]);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div id="landingPage">
      <div id="landingPageTopContainer">
        <a href="/">
          <img id="logo" src={logo} alt="Logo" />
        </a>
        <div id="buttonsContainer">
          <a href="/login" data-testid="login" className="button button-white button-no-border">
            Log in
          </a>
          <a href="/signup" data-testid="signup" className="button button-white">
            Sign up
          </a>
        </div>
      </div>
      <div id="landingPageInner">
        <div className="gradient gradient-large"></div>
        <h2>Engaging Coding Practice for Ongoing Skill Development</h2>
        <h1>Bite-sized, intelligent coding practice</h1>
        <a href="/signup" id="getStartedButton" className="button-no-border">
          Get Started for Free
        </a>
        <div id="editorAnimationWrapper">
          <div id="editorAnimation">
            <video autoPlay loop muted ref={videoRef}>
              <source src={video} type="video/mp4" />
            </video>
          </div>
          <span id="togglePlayPauseButton" onClick={handlePlayPause}>
            {isPlaying ? "Pause" : "Play"}
          </span>
        </div>
        <div id="testimonialsContainer">
          <Testimonial
            avatarSrc={lucaAvatar}
            quote="CodeAcer is a game-changer. It challenges you to both write code and explain your understanding in English. This dual approach helps you practice coding while deepening your grasp of key concepts. Highly recommend for anyone serious about honing their programming skills!"
            name="Luca Sato-Nioi"
            position="Senior Engineer"
          />
          <div className="gradient gradient-small gradient-small-testimonial"></div>
        </div>
        <div id="coursesContainer">
          <h3>
            Practice <span>JavaScript</span>
            <div id="jsImageWrapper">
              <img src={jsImage} alt="JavaScript" />
            </div>
          </h3>
          <p>
            Choose between beginner or intermediate JavaScript practice.
            <br />
            More skill levels, languages and frameworks are coming soon.
          </p>
        </div>
        <div id="pricingContainer">
          <Pricing />
          <div id="annualArrowImageWrapper">
            <img src={annualArrowImage} alt="Annual Arrow" />
          </div>
          <div className="gradient gradient-small gradient-small-pricing"></div>
        </div>
        <div id="footerWrapper">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Index;
